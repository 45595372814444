var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "400", scrollable: "", persistent: "" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "popup add_store_popup" },
        [
          _c("v-card-title", [
            _c("h3", { staticClass: "page-title-bar" }, [
              _c("i", { staticClass: "ico ico-system" }),
              _vm._v(_vm._s(_vm.$t(_vm.titleText)) + " ")
            ])
          ]),
          _c("v-card-text", [
            _c(
              "div",
              [
                _c("v-text-field", {
                  staticClass: "form-input",
                  attrs: {
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    solo: "",
                    placeholder:
                      "" +
                      _vm.$t("Input the " + this.action + " " + this.category),
                    clearable: ""
                  },
                  on: { keydown: _vm.clearTabindex },
                  model: {
                    value: _vm.input,
                    callback: function($$v) {
                      _vm.input = $$v
                    },
                    expression: "input"
                  }
                })
              ],
              1
            )
          ]),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-center" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.clickSave }
                },
                [_vm._v(_vm._s(_vm.$t("Save")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "btn",
                  attrs: { text: "", icon: "" },
                  on: { click: _vm.closeModal }
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }